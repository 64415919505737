.post-payment-container {
    text-align: center;
    margin-top: 50px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    color: #4caf50;
    font-size: 2.5em;
  }
  
  p {
    color: #555;
    font-size: 1.2em;
    margin-bottom: 30px;
  }
  
  .confirm-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
  }
  
  .confirm-button:hover {
    background-color: #45a049;
  }
  