.about-container {
  text-align: center;
}
.about-title-image-container {
  width: 100%;
  height: 400px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/aboutTitleImage.jpg"); /* Adjust opacity */

  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;
}
.about-1-text {
  color: #fff;
  text-align: center;
  font-family: "Roboto Condensed";
  font-size: 70px;
  font-style: normal;
  font-weight: 800;
  line-height: 90px; /* 128.571% */
  text-transform: uppercase;
}

.about-2-container {
  text-align: center;
  margin: 5% 12%;
}
.about-2-title {
  color: #032149;
  text-align: center;
  font-family: "archivo-condensed";
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 50px; /* 125% */
  text-transform: capitalize;
}
.about-2-text {
  color: #848484;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 135% */
  
}

.about-3-container {
  display: flex;  
  /* flex-wrap: wrap; */
  box-sizing: border-box;
  text-align: left;
  background: rgba(242, 242, 242, 0.50);
  
  justify-content: space-between;
  padding: 3% 8%;
  align-items: center;
}
.about-3-img {
  border-radius: 20px;
  /* width: 570px; */
  width: 45%;
  max-width: 100%;
  /* min-width: 300px; */
  max-height: 380px;
}
.about-3-right {
  width: 45%;
  padding: 0 20px;
}
.about-inner-title {
  color: #032149;
font-family: "archivo-condensed";
font-size: 40px;
font-style: normal;
font-weight: 900;
line-height: 50px; /* 125% */
text-transform: capitalize;
}
.about-3-txt {
  text-align: left;
  /* max-width: 50%; */
  color: #848484;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 135% */
}
.about-4-container {
  color: #fff;  
  text-align: center;
  background: linear-gradient(276deg, #0056D6 0%, #032149 100%);  
  padding: 3% 10%;
}

.about-5-container {
  display: flex;
  padding: 3% 12%;
}
.about-5-inner-text {
  text-align: left;
  margin: 4%;
}
.about-6-img {
  width: 90%;  
}

.about-6-container {
  margin-bottom: 5%;
}
@media screen and (max-width: 1181px) {
  .about-inner-title {
    padding-top: 8%;
  }
  .about-3-txt {
    text-align: center;    
  }
  .about-3-img {
    width: 100%;    
  }
  .about-5-inner-text {
    text-align: center;

  }
  .about-6-img {
    width: 60%;
  }
}