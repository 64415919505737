.hiw-1-container {
  width: 100%;
  height: 400px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/hiw1Image.png"); /* Adjust opacity */

  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;
}

.hiw-2-container {
  max-width: 90%;
  margin: auto;
  margin-top: 4%;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}
.hiw-2-title {
  color: #032149;
  text-align: center;
  font-family: "archivo-condensed";
  font-size: 45px;
  font-style: normal;
  font-weight: 900;
  line-height: 50px; /* 111.111% */
}
.hiw-2-text {
  color: #848484;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 135% */
}
.hiw-3-image-container {
  text-align: center;
  width: 100%;
  margin-bottom: 5%;
}
.hiw-3-pc {
  width: 100%;
  max-width: 1200px;  
}