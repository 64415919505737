.contact-container {
  padding: 0 10%;
  margin: auto;
  margin-top: 2%;
  box-sizing: border-box;
}
.contact-title {
  color: #032149;
  text-align: left;
  font-family: "archivo-condensed";
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 50px; /* 125% */
  color: #032149;

}
#contact-hr {
  width: 85%;
  height: 3px;
  color: #AFAFAF;
}
.contact-form-title {
  color: #848484;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 135% */
}
.error-message {
  background-color: red;
  color: white;
  padding: 10px;
  border-radius: 7px;
}
.contact-inner-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contact-inner-right {
  width: 28%;
  display: flex;
  flex-direction: column;
  margin: 2%;
}

.contact-inner-right1 {
  border-radius: 20px;
  border: 1px solid #afafaf;
  opacity: 0.5;
  background: #fff;
  /* height: 271px; */
  margin-bottom: 8%;
  max-width: 320px;
  min-width: 211px;
  padding: 19px;
}

.contact-inner-right1 > p {
  display: inline;
  font-size: 16px;
}
.contact-social {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.contact-social-img {
  height: 25px;
  margin-right: 3%;
}

.contact-inner-right2 {
  border-radius: 20px;
  background: #063f8b;  
  padding: 19px;
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  min-width: 211px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}
.contact-form {
  width: 45%;
  display: flex;
  flex-direction: column;
}
.contact-input {
  /* max-width: 80%; */
  border-radius: 25px;
  border: 1px solid #afafaf;
  opacity: 0.5;
  margin: 12px;
  padding: 0 20px;
  max-width: 640px;
  height: 60px;
  flex-shrink: 0;
}

.contact-btn {
  margin: 12px;
  width: 90%;
  height: 50px;
  /* margin: auto; */
}

.contact-btn:disabled {
  background-color: grey;
  display: block;
  margin-top: 0;
}

.not-logged-msg {
  margin-left: 5%;
  color: red;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
    .contact-container {
      padding: 0 5%; /* Reduce padding on mobile */
    }
  
    .contact-inner-container {
      flex-direction: column; /* Stack elements vertically */
    }
  
    .contact-form {
      width: 100%; /* Full width on mobile */
    }
  
    .contact-inner-right {
      width: 100%; /* Full width on mobile */
      margin: 20px 0; /* Adjust margins */
    }
  
    .contact-input {
      /* width: 100%; */
      max-width: 100%;
    }
  
    .contact-title {
      font-size: 28px; /* Smaller title on mobile */
      line-height: 34px;
    }
  
    .contact-form-title {
      font-size: 16px; /* Smaller subtitle on mobile */
      line-height: 22px;
    }
  }