/* Base styles */
#tos-container {
    width: 100%;
    box-sizing: border-box;
    padding: 5% 10%;
    background-color: var(--white);
  }
  
  #tos-header-container {
    text-align: center;
    margin-bottom: 40px;
  }
  
  #tos-header {
    color: #032149;
    font-family: "archivo-condensed", sans-serif;
    font-size: 40px;
    font-weight: 900;
    line-height: 1.4;
  }
  
  #tos-content-container {
    color: #032149;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 1.6;
  }
  
  .tos-subheader {
    color: #032149;
    font-family: "archivo-condensed", sans-serif;
    font-size: 30px;
    font-weight: 900;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .tos-text {
    color: #848484;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  
  /* Mobile and small screens */
  @media screen and (max-width: 1181px) {
    #tos-container {
      padding: 5%;
    }
  
    #tos-header {
      font-size: 30px;
    }
  
    .tos-subheader {
      font-size: 25px;
    }
  
    .tos-text {
      font-size: 16px;
    }
  }