.faq-container {
  text-align: center;  
}
.faq-title-image-container {
  width: 100%;
  height: 400px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/faq1.jpg"); /* Adjust opacity */

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;
}
.faq-content-container {
    max-width: 90%;
    text-align: left;
    margin: auto;
}
.faq-1-text {
  color: #fff;
  text-align: center;
  font-family: "Roboto Condensed";
  font-size: 70px;
  font-style: normal;
  font-weight: 800;
  line-height: 90px; /* 128.571% */
  text-transform: uppercase;
}

.faq-title {
  color: #032149;
  text-align: center;
  font-family: "archivo-condensed";
  font-size: 45px;
  font-style: normal;
  font-weight: 900;
  line-height: 50px; /* 111.111% */
  margin-top: 50px;
  margin-bottom: 30px;
}

.faq-sub-title {
  color: #848484;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 135% */
}

.faq-inner-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
  justify-content: center;
  margin: auto;
  margin-top: 1%;
}
.faq-element-container {
  width: 500px;
  margin: 0 1%;
}
.faq-element-container h5 {
  border-radius: 50px;
  height: 51px;
  background: linear-gradient(90deg, #0056d6 0%, #063f8b 100%);
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 25px;
  color: #fff;  
  font-family: "Roboto Condensed";
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  /* text-transform: uppercase; */
}
.faq-element-container p {
  border-radius: 30px;
  border-bottom: 2px solid #0056d6;
  background: #f2f2f2;
  /* height: 97px; */
  padding: 2%;
}
.faq-element-container h5:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
  .faq-inner-container {
    /* margin: 1%; */
    max-width: 95%;
  }
  .faq-element-container {
    width: 100%;
    margin: 0 1%;
  }
  .faq-element-container h5 { 
    font-size: 14px;
  }
  .faq-element-container p { 
    font-size: 14px;
    padding: 5%;
  }
}