.adv-container {
  padding-top: 5%;
}
.adv-title {
  color: #fff;
  text-align: center;
  font-family: "archivo-condensed";
  font-size: 35px;
  font-style: normal;
  font-weight: 900;
  line-height: 50px; /* 142.857% */
}
.adv-text {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 135% */
}

.adv-inner-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.adv {
  /* width: 274px;
  text-align: left;
  padding: 2%; */
  border: 2px solid #fff;
  margin: 1%;
  border-radius: 20px;
  display: flex;
  width: 274px;
  height: 315px;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}
.adv img {
  max-width: 30px;
}

.adv-inner-title {
  color: #fff;
  font-family: "archivo-condensed";
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  margin: 4%;
  text-transform: capitalize;
}
.adv-inner-text {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 27px; 158.824% */
}

@media (max-width: 768px) {
  .adv {
    max-width: 100%;
    margin: 2% auto;
    padding: 5%;
  }
  .adv-inner-title {
    margin-left: 0;
  }
}
