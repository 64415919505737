.pre-checkout-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  /* max-width: 500px; */
  width: 100%;
  background: white;
  font-size: 16px;
}

.pre-checkout-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
}

.pre-checkout-description {
  font-size: 16px;
  color: #555;
  margin-bottom: 12px;
  text-align: left;
}

.pre-checkout-read-more {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
}

.pre-checkout-info {
  text-align: left;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  color: #555;
  margin-bottom: 12px;
}

.pre-checkout-change {
  font-weight: bold;
}

.pre-checkout-positive {
  color: green;
}

.pre-checkout-negative {
  color: red;
}

.pre-checkout-website {
  display: block;
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 12px;
}

.pre-checkout-website:hover {
  text-decoration: underline;
}

.pre-checkout-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
}

.pre-checkout-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  width: auto;
}

.pre-checkout-btn:hover {
  background-color: #0056b3;
}
.pre-checkout-not-logged {
  color: red;
  font-weight: 500;  
}