/* Base styles (for standard screens) */
#home-container {
  width: 100%;
  box-sizing: border-box;
}


#home-1-container {  
    width: 100%;    
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../../assets/homeMobileImage.png");    

    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  


  height: 60vh;
  position: relative;
  overflow: hidden;
}

#home-1-video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  top: 0;
  left: 0;
}

#home-1-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10%;
  color: var(--white);
  font-size: 70px;
  text-align: center;
  margin: 0;
}

.home-1-inner-text {
  color: var(--white);
  font-size: 35px;
  margin: 15px 0;
}

.home-1-btns-container {
  width: 30%;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.home-1-btns-container > button {
  height: 50px;
  font-size: 16px;
  min-width: 200px;
  max-width: 100%;
  padding: 0 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.home-1-btns-container > button:hover {
  opacity: 0.9;
}

.home-1-btn-2 {
  color: #0056d6;
  background-color: white;
  font-family: Roboto;
  font-weight: 400;
}

#home-2-container {
  display: flex;
  flex-direction: row;
  padding: 3% 10%;
  align-items: flex-start;
  gap: 30px;
}

#home-2-image {
  width: 32%;
  border-radius: 20px;
  margin: auto;
}

#home-2-right-container {
  margin-left: 138px;
  text-align: left;
}

#home2-header {
  color: #032149;
  font-family: "archivo-condensed", sans-serif;
  font-size: 35px;
  font-weight: 900;
  line-height: 1.4;
  margin-bottom: 20px;
}

#home-2-text {
  color: #848484;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 20px;
  padding-right: 50px;
}

#home-3-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
  padding: 2% 10%;
  width: 100%;
  box-sizing: border-box;
}

#home-4-container {
  padding: 2% 10%;
  width: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 1181px) {
  #home-1-container {
    width: 100%;
    height: 500px;        

    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #home-1-text-container {
    font-size: 40px;
    padding: 5%;
  }

  .home-1-inner-text {
    font-size: 25px;
  }

  .home-1-btns-container {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .home-1-btns-container > button {
    margin: 10px 0;
  }

  #home-2-container {
    flex-direction: column;
    align-items: center;
    padding: 5%;
  }

  #home-2-image {
    /* width: 100%;
    max-width: 400px; */
    width: 81%;
    /* height: 208px; */
    flex-shrink: 0;
  }

  #home-2-right-container {
    margin-left: 0;
    text-align: center;
  }
  #home-2-text {
    text-align: left;
  }

  #home2-header {
    font-size: 30px;
  }

  #home-2-text {
    font-size: 16px;
    padding-right: 0;
  }

  #home-3-container {
    flex-direction: column;
    padding: 5%;
  }
  #home-4-container {
    flex-direction: column;
    padding: 5%;
  }
}
