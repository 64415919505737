.search-modal-container {
  max-width: 99%;  
  background: linear-gradient(180deg, #032149 0%, #074faf 100%);
    padding: 4%;
    margin: 0;
  }

  .search-modal-input {
    border-radius: 5px;
    width: 90%;
    height: 50px;
    
  }

  .sm-input-container {
    text-align: center;
  }

.stock-item-container {
  margin: 2%;
  border-radius: 20px;
  background: #fff;
  display: flex;
  align-items: center;  
  padding: 2%;
  justify-content: space-between;
}
.stock-item-symbol {
  color: #0060df;
  margin: 0; /* Remove default margin */

  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 20px;
  padding: 0; /* Remove default padding */

}
.stock-item-desc {
  color: #000;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0; /* Remove default margin */

  padding: 0; /* Remove default padding */

}

Copy.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.stock-item-container:hover {
  cursor: pointer;
}


.stock-item-container {
  margin: 2%;
  border-radius: 20px;
  background: #fff;
  display: flex;
  align-items: center;  
  padding: 2%;
  justify-content: space-between;
}
.stock-item-symbol {
  color: #0060df;
  margin: 0; /* Remove default margin */

  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 20px;
  padding: 0; /* Remove default padding */

}
.stock-item-desc {
  color: #000;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0; /* Remove default margin */

  padding: 0; /* Remove default padding */

}

Copy.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.stock-item-container:hover {
  cursor: pointer;
}
