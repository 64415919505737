.stocks-table-container {
  width: 50%;
}

.stocks-table th,
.stocks-table td {
  padding: 0.5rem;
  text-align: left;
}

.top-stocks-header {
  /* display: flex; */
  justify-content: space-around;
  align-items: center;
  width: 85%;
  color: #000;
  font-family: "archivo-condensed";
  font-size: 25px;
  font-style: normal;
  font-weight: 900;  
}
.table-header-title {
  width: 240px;
}
.table-search {
  border: 0.5;
  width: 250px;
  font-size: 16px;
  margin-left: 3%;
}


.symbol-name {
  display: inline;
}
.symbol-image {
  width: 30px;
  height: 30px;
  display: inline;
  border-radius: 50%;
  margin-right: 3%;
  object-fit: cover; /* Ensures image fills the container while maintaining aspect ratio */
  vertical-align: middle; /* Helps with vertical alignment */
}
.stocks-table {
  width: 85%;
  border-collapse: collapse;
  margin: 0px;
}
.table-stocks-symbol{
  /* display: flex; */
  min-width: 110px;
  align-items: center;
  height: 100%;
  align-self: stretch;
  padding: 0 !important;
  font-weight: bold;
  /* background-color: green; */
}
.stock-symbol {
  /* height: 100%;  */
  /* font-weight: bold; */
  /* background-color: green; */
}
.table-stocks-symbol span {
  margin-left: 8px; /* Add spacing between image and text */
}
.table-stocks-symbol :hover{
  cursor: pointer;
}
.stocks-table-container > hr {
  margin-right: 15%;
}



.stocks-table tr {
  border-bottom: 1px solid #e5e7eb;
}


.stock-price {
  text-align: right;
}


.stock-change.positive {
  color: #10b981;
}

.stock-change.negative {
  color: #ef4444;
}

.change-icon {
  margin-right: 0.25rem;
}
